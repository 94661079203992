<template>
  <div class="box">
    <div class="is-flex is-flex-direction-column">
      <div>
        <Popper>
          <div class="button">
            <span class="icon is-small">
              <i class="fas fa-plus"></i>
            </span>
            <span>New Palette</span>
          </div>

          <template #content>
            <div class="box">
              <div class="card">
                <input
                  type="text"
                  class="input"
                  v-model="newPaletteName"
                  @keyup.enter="onAddNew(newPaletteName)"
                />
              </div>
              <div class="block"></div>
              <div class="is-flex is-justify-content-center">
                <button @click="onAddNew(newPaletteName)" class="button is-small is-primary">
                  Save
                </button>
              </div>
            </div>
          </template>
        </Popper>
      </div>

      <div :key="i" class="is-flex is-flex-direction-column" v-for="(palette, i) in palettes">
        <div class="block"></div>
        <div class="level">
          <div class="level-left">
            <span class="level-item is-clickable">
              <Popper>
                <span class="icon is-small is-clickable">
                  <i class="fas fa-pen"></i>
                </span>

                <template #content>
                  <div class="box">
                    <div class="card">
                      <input
                        type="text"
                        class="input"
                        v-model="newPaletteName"
                        @keyup.enter="updatePaletteName(i, newPaletteName)"
                      />
                    </div>
                    <div class="block"></div>
                    <div class="is-flex is-justify-content-center">
                      <button
                        @click="updatePaletteName(i, newPaletteName)"
                        class="button is-small is-primary"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </template>
              </Popper>
            </span>
            <span class="level-item">
              <h5 class="subtitle is-5">{{ palette.name }}</h5>
            </span>
          </div>
          <div class="level-right is-clickable" @click="onDeletePalette(i)">
            <span class="level-item">
              <span class="icon is-small">
                <i class="fas fa-times"></i>
              </span>
            </span>
          </div>
        </div>
        <div class="columns is-multiline" style="border-bottom: 1px solid whitesmoke">
          <template :key="index" v-for="(color, index) in palette.palette">
            <div class="column is-2-desktop is-4-tablet hover">
              <div
                class="card is-shadowless is-relative"
                :style="`
                padding: 2rem 1rem;
                background-color: ${color};
                border: 1px dotted lightgray;
               
              `"
              >
                <button @click="onDeleteColor(i, index)" class="delete is-overlay"></button>
              </div>
              <span class="my-3">{{ color }}</span>
              <div class="block" style=""></div>
            </div>
          </template>
          <div class="column is-2-desktop is-4-tablet">
            <Popper>
              <div
                class="card is-shadowless is-clickable"
                style="
                  padding: 1.5rem 2rem;
                  background-color: lightgray;
                  border: 1px dotted lightgray;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <span class="icon is-small">
                  <i class="fas fa-plus"></i>
                </span>
              </div>
              <template #content>
                <div class="card">
                  <ColorPickerVue
                    @changeColor="
                      (colorObj) => {
                        newColor = colorObj
                      }
                    "
                  />
                </div>
                <div class="card-footer">
                  <button @click="onAddNew(palette.name, newColor)" class="button is-primary">
                    Save
                  </button>
                </div>
              </template>
            </Popper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, watchEffect } from 'vue'

import BrandKit from '@/services/brandKits'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'

import ColorPickerVue from '@/components/ColorPicker'

export default {
  components: { ColorPickerVue },
  setup() {
    const isLoading = ref(false)
    const $alert = inject('$alert')
    const { $vfm, showLoadingModal, showUploadImageModal } = CommonModals()
    const { user, brandKit, refreshBrandKit } = yearlyStore()
    const palettes = ref(brandKit.value?.colors || [])
    const newColor = ref(null)
    const newPaletteName = ref(null)
    async function onDeleteColor(paletteIndex, colorIndex) {
      if (palettes.value.length) {
        let tempPalettes = [...palettes.value]

        let tempPalette = { ...palettes.value[paletteIndex] }

        tempPalette.palette = [
          ...tempPalette.palette.slice(0, colorIndex),
          ...tempPalette.palette.slice(colorIndex + 1, tempPalette.palette.length)
        ]

        tempPalettes = [
          ...palettes.value.slice(0, paletteIndex),
          tempPalette,
          ...palettes.value.slice(paletteIndex + 1, palettes.value.length + 1)
        ]
        try {
          await updatePalette(tempPalettes)
          palettes.value = [...tempPalettes]
        } finally {
          isLoading.value = false
        }
      }
    }
    async function onDeletePalette(paletteIndex) {
      if (palettes.value.length && palettes.value[paletteIndex]) {
        let tempPalettes = [...palettes.value]
        tempPalettes = [
          ...palettes.value.slice(0, paletteIndex),
          ...palettes.value.slice(paletteIndex + 1, palettes.value.length + 1)
        ]
        try {
          await updatePalette(tempPalettes)
          palettes.value = [...tempPalettes]
        } finally {
          isLoading.value = false
        }
      }
    }
    async function onAddNew(paletteName, color) {
      let c = color?.hex
      let paletteIndex = palettes.value.findIndex((palette) => palette.name == paletteName)

      if (~paletteIndex) {
        palettes.value[paletteIndex].palette.push(c)
      } else {
        palettes.value = [...palettes.value, { name: paletteName, palette: c ? [c] : [] }]
      }
      await updatePalette(palettes.value)
    }
    async function updatePalette(palettes) {
      try {
        isLoading.value = true

        await BrandKit.api.updateBKColors(user.value.firebaseId, brandKit.value.id, palettes)
        await refreshBrandKit()
        palettes.value = brandKit.value?.colors || []
        $alert.alert({
          message: 'Successfully updated your palette',
          type: 'success'
        })
      } catch (e) {
        $alert.alert({
          message: 'An error occured uploading your image, please try again',
          type: 'error'
        })
        throw e
      } finally {
        isLoading.value = false
        newColor.value = null
        newPaletteName.value = null
      }
    }
    async function updatePaletteName(paletteIndex, name) {
      if (palettes.value.length && palettes.value[paletteIndex]) {
        let tempPalettes = [...palettes.value]
        tempPalettes[paletteIndex].name = name
        try {
          await updatePalette(tempPalettes)
          palettes.value = [...tempPalettes]
        } finally {
          isLoading.value = false
        }
      }
    }
    watchEffect(async () => {
      if (isLoading.value) {
        await showLoadingModal()
      } else {
        await $vfm.hide('LoadingModal')
      }
    })
    return {
      isLoading,
      palettes,
      onAddNew,
      newColor,
      newPaletteName,
      onDeleteColor,
      onDeletePalette,
      updatePaletteName
    }
  }
}
</script>

<style lang="scss" scoped></style>
