import { CollectionManager as __CollectionManager, Pagination } from '@thinknimble/tn-models'

export default class CollectionManager extends __CollectionManager {
  constructor({
    list = [],
    pagination = Pagination.create(),
    refreshing = false,
    loadingNextPage = false,
    filters = {},
    ModelClass = null
  } = {}) {
    super({ list, pagination, refreshing, loadingNextPage, filters, ModelClass })
  }
  static create(opts = {}) {
    opts = opts || {}
    return new CollectionManager(opts)
  }
  update(data, append = false) {
    this.list = [...(append ? this.list : []), ...data.results]
    this.pagination = Pagination.create({
      ...this.pagination,
      next: data.next,
      previous: data.previous,
      totalCount: data.count,
      size: data.size
    })
    return this
  }
}
