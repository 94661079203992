<template>
  <iframe
    src="http://staging.app.yearly.report/#/from/pb1646a/test"
    style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      border: none;
      z-index: 9999;
    "
    scrolling="yes"
  ></iframe>
</template>

<script>
import { onBeforeMount, onMounted, ref, toRefs, watchEffect, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import yearlyStore from '@/composables/yearlyStore'
import commonModals from '@/composables/commonModals'
import { firebaseConfig } from '@/services/config'
import store from '@/store'

import axios from 'axios'

import CreditCardComponent from '@/components/CreditCardComponent'
import SubscriptionOptions from '@/views/Dashboard/__SubscriptionOptions'

export default {
  components: { CreditCardComponent, SubscriptionOptions },

  setup() {
    const route = useRoute()
    const isLoading = ref(false)
    const { user, setUser, setToken, state } = yearlyStore()
    const $vfm = inject('$vfm')
    const { showLoadingModal, showCreditCardModal } = commonModals()

    async function onRetry() {
      const refreshUrl = `https://securetoken.googleapis.com/v1/token?key=${firebaseConfig.apiKey}`

      const refreshData = {
        grant_type: 'refresh_token',
        refresh_token: store.getters?.user?.refreshToken
      }
      await retryRequest(refreshUrl, refreshData)
    }
    async function retryRequest(refreshUrl, refreshData) {
      console.log(refreshUrl, refreshData)
      try {
        const res = await axios.post(refreshUrl, refreshData, {})
        return res.data
      } catch (e) {
        console.log(e)
        console.log('Cant Refresh token returned ', e)
        throw e
      }
    }

    onMounted(async () => {})
    watchEffect(async () => {})

    return {
      onRetry,
      retryRequest
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background: #ccc;
  padding: 20px;
}

.very-specific-design {
  padding: 50px;
  text-align: center;
  background: white;
  position: relative;
  /*   left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center; */
}

.scaleable-wrapper {
  padding: 20px;
  resize: both;
  position: relative;
  background: #666;
}

.ui-resizable-se {
  width: 10px;
  height: 10px;
  background: orange;
  position: absolute;
  bottom: 0;
  right: 0;
}

.bigred {
  color: red;
  font-size: 5rem;
}
.test {
  background-image: url('~@/assets/yearly-teal.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.custom-container {
  padding: 0rem 4rem;
}
</style>
