<template>
  <div class="container">
    <div class="columns">
      <div class="column">
        <h5 class="subtitle is-5 mt-4">
          Image Library
          <span
            v-tooltip:bottom.tooltip="
              'Save your brand images and easily access them \n as you design'
            "
            class="icon is-small is-clickable"
          >
            <i class="fas fa-question fa-xs"></i>
          </span>
        </h5>
        <BrandKitImages />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h5 class="subtitle is-5">
          Brand Colors
          <span
            v-tooltip:bottom.tooltip="
              'Save your brand colors and easily access them \n as you design'
            "
            class="icon is-small is-clickable"
          >
            <i class="fas fa-question fa-xs"></i>
          </span>
        </h5>
        <BrandKitColors />
      </div>
      <div class="column">
        <h5 class="subtitle is-5">
          Brand Fonts
          <span
            v-tooltip:bottom.tooltip="
              'Save your brand fonts and easily access them \n as you design'
            "
            class="icon is-small is-clickable"
          >
            <i class="fas fa-question fa-xs"></i>
          </span>
        </h5>
        <BrandKitFonts />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'

import BrandKit, { Font } from '@/services/brandKits/models'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'

import BrandKitColors from './__BrandKitColors.vue'
import BrandKitFonts from './__BrandKitFonts.vue'
import BrandKitImages from './__BrandKitImages.vue'
import { useRouter } from 'vue-router'
export default {
  components: { BrandKitColors, BrandKitImages, BrandKitFonts },
  setup() {
    const router = useRouter()
    const { user, hasPremium, hasPremiumPlus, brandKit, setBrandKit } = yearlyStore()
    const { showUpgradeFromFreeModal } = CommonModals()
    const loadingBrandKit = ref(false)
    onMounted(async () => {
      if (!hasPremium.value) {
        await showUpgradeFromFreeModal({ closed: () => router.push({ name: 'Account' }) })
        return
      }
      try {
        loadingBrandKit.value = true

        const res = await BrandKit.api.retrieve(user.value.firebaseId)

        setBrandKit(res)
      } catch (e) {
        console.log(e)
      } finally {
        loadingBrandKit.value = ref(false)
      }
    })
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
