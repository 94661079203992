<template>
  <div class="box">
    <div class="columns is-multiline" style="margin-top: 1rem">
      <div class="column is-2 is-2-mobile is-clickable">
        <div @click="onAddNew" style="height: 100%; width: 100%" class="is-clickable">
          <div
            class="card is-shadowless"
            style="
              height: 100%;
              width: 100%;
              padding: 3rem 2rem;
              background-color: lightgray;
              border: 1px dotted lightgray;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <span class="icon">
              <i :class="`fas fa-plus`"></i>
            </span>
          </div>
        </div>
      </div>
      <div :key="i" class="column is-2 is-2-mobile" v-for="(image, i) in images">
        <div class="card is-shadowless">
          <div class="card-image is-relative">
            <figure class="image is-3by2">
              <img :src="image.url" style="object-fit: cover" />
            </figure>
            <button @click="onDelete(image.id)" class="delete is-overlay"></button>
          </div>
          <footer class="card-footer"></footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, watchEffect } from 'vue'

import BrandKit from '@/services/brandKits'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'
export default {
  setup() {
    const isLoading = ref(false)
    const $alert = inject('$alert')
    const { $vfm, showLoadingModal, showUploadImageModal } = CommonModals()
    const { user, brandKit, refreshBrandKit } = yearlyStore()
    const images = ref(brandKit.value?.images || [])
    async function onAddNew() {
      let action = {
        fileLoaded: async (e) => {
          try {
            isLoading.value = true

            await BrandKit.api.addBKImage(user.value.firebaseId, brandKit.value.id, e)
            await refreshBrandKit()
            images.value = brandKit.value?.images || []
          } catch (e) {
            $alert.alert({
              message: 'An error occured uploading your image, please try again',
              type: 'error'
            })
          } finally {
            isLoading.value = false
            $vfm.hide('ImageUploadModal')
          }
        }
      }
      await showUploadImageModal(action)
    }
    async function onDelete(imageId) {
      try {
        isLoading.value = true

        await BrandKit.api.deleteBKImage(user.value.firebaseId, brandKit.value.id, imageId)
        await refreshBrandKit()
        images.value = brandKit.value?.images || []
      } catch (e) {
        $alert.alert({
          message: 'An error occured removing your image, please try again',
          type: 'error'
        })
      } finally {
        isLoading.value = false
        $vfm.hide('ImageUploadModal')
      }
    }
    watchEffect(async () => {
      if (isLoading.value) {
        await showLoadingModal()
      } else {
        await $vfm.hide('LoadingModal')
      }
    })

    return {
      onAddNew,
      images,
      onDelete
    }
  }
}
</script>

<style lang="scss" scoped></style>
