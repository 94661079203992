<template>
  <div class="is-flex">
    <nav class="pagination is-small" role="navigation" aria-label="pagination">
      <button
        @click="collection.prevPage()"
        :disabled="!collection.pagination.hasPrevPage"
        class="pagination-previous"
      >
        Previous
      </button>
      <button
        @click="collection.nextPage()"
        :disabled="!collection.pagination.hasNextPage"
        class="pagination-next"
      >
        Next page
      </button>
      <!--       <ul class="pagination-list">
        <li><a class="pagination-link" aria-label="Goto page 1">1</a></li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li><a class="pagination-link" aria-label="Goto page 45">45</a></li>
        <li>
          <a class="pagination-link is-current" aria-label="Page 46" aria-current="page">46</a>
        </li>
        <li><a class="pagination-link" aria-label="Goto page 47">47</a></li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li><a class="pagination-link" aria-label="Goto page 86">86</a></li>
      </ul> -->
      <ul class="pagination-list">
        <li>
          {{
            `Page ${collection.pagination.page} of ${collection.pagination.calcTotalPages(
              collection.pagination
            )} Pages`
          }}
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { CollectionManager } from '@thinknimble/tn-models'
import { computed, toRefs } from '@vue/reactivity'
export default {
  props: {
    collection: {
      type: CollectionManager,
      default: CollectionManager.create()
    }
  },
  setup(props) {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
