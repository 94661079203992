<template>
  <div class="box">
    <div class="container">
      <ul class="">
        <li class="level">
          <div class="level-item">
            <button class="button is-primary" @click="onShowUploadFontModal">Upload Font</button>
          </div>
        </li>
        <div class="block"></div>
        <li
          style="border-bottom: 1px solid whitesmoke"
          :key="i"
          v-for="(font, i) in fontsFromUser"
          class="level"
        >
          <span class="level-left" :style="`flex: 1 0 auto; font-family:${font.generatedName};`">{{
            font.generatedName
          }}</span>
          <div class="level-right">
            <span class="level-item" :style="`text-transform: capitalize;`">{{ font.style }}</span>

            <button
              @click="onDelete(font.id)"
              style="padding: 0 0.8rem"
              class="level-item button is-danger is-inverted is-small is-rounded"
            >
              <span class="icon is-small">
                <i class="fas fa-times"></i>
              </span>
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, toRefs, computed, inject, watchEffect } from 'vue'
import BrandKit, { Font } from '@/services/brandKits/models'

import yearlyStore from '@/composables/yearlyStore'
import CommonModals from '@/composables/commonModals'

import FileUpload from '@/components/FileUpload'

export default {
  component: { FileUpload },
  setup(props, { emit }) {
    const $alert = inject('$alert')

    const { user, brandKit, refreshBrandKit } = yearlyStore()
    const isLoading = ref(false)

    const { $vfm, showLoadingModal, showUploadFontModal } = CommonModals()

    const fontsFromUser = computed(() =>
      brandKit.value?.fonts?.map((f) => Font.create({ ...f, isCustom: true }))
    )

    fontsFromUser.value?.forEach(async (f) => {
      try {
        await f.loadFont()
      } catch (e) {
        console.log(e)
      }
    })
    async function onDelete(fontId) {
      try {
        isLoading.value = true
        await BrandKit.api.deleteFont(user.value.firebaseId, brandKit.value.id, fontId)
        await refreshBrandKit()
      } catch (e) {
        $alert.alert({
          message: 'An error occured removing your font, please try again',
          type: 'error'
        })
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }
    async function onShowUploadFontModal() {
      await showUploadFontModal('', '', {
        'font-form-ready': async (e) => {
          try {
            isLoading.value = true
            const fontFile = e.file
            //todo e.file is deleted in the api
            const font = await BrandKit.api.addFont(user.value.firebaseId, brandKit.value.id, e)
            await BrandKit.api.addFontFile(
              user.value.firebaseId,
              brandKit.value.id,
              font.id,
              fontFile
            )
            $alert.alert({ message: 'Succesfully Added new Font', type: 'success' })
            await refreshBrandKit()
          } catch (e) {
            console.log(e)
            $alert.alert({ message: 'En error occured uploading your font please try again' })
          } finally {
            isLoading.value = false
            $vfm.hide('BrandKitFontModal')
          }
        }
      })
    }
    watchEffect(async () => {
      if (isLoading.value) {
        await showLoadingModal()
      } else {
        await $vfm.hide('LoadingModal')
      }
    })

    return {
      fontsFromUser,
      onShowUploadFontModal,
      onDelete
    }
  }
}
</script>

<style lang="scss" scoped></style>
