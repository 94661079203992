<template>
  <div class="container">
    <div class="">
      <div
        style="
          width: 50px;
          height: 50px;
          position: relative;
          top: -30px;
          margin-left: auto;
          z-index: 880;
        "
      >
        <button
          @click="alert('Trying to start a new report? Login to Yearly 3.0 to continue.')"
          class="button is-success is-rounded"
          style="height: 100%; width: 100%"
        >
          <span class="icon is-small">
            <i class="fas fa-plus"></i>
          </span>
        </button>
      </div>
      <div class="box-content">
        <div class="columns is-multiline is-centered is-desktop">
          <div v-if="isLoading" class="column is-three-fifths is-offset-one-fifth">
            <div class="is-flex is-justify-content-center">
              <LogoRandomizer />
            </div>
          </div>

          <template v-else>
            <div class="column is-12">
              <span>
                Looking for
                <a class="link" href="https://legacy.app.yearly.report/">legacy reports</a>
              </span>
              <span
                v-tooltip:bottom.tooltip="'Reports built with the previous builder'"
                class="icon is-small is-clickable"
              >
                <i class="fas fa-question fa-xs"></i>
              </span>
            </div>
            <div v-if="!reports.list.length">
              <h3 class="subtitle is-5" @click="onNewReport">
                Welcome to Yearly, <a> create your first report </a>
              </h3>
            </div>
            <template v-else>
              <div
                class="column is-4-desktop is-12-tablet is-12-mobile"
                :key="report.id"
                v-for="report in reports.list"
              >
                <div class="is-relative">
                  <div style="position: absolute; top: 10px; left: 95%; z-index: 990">
                    <Popper>
                      <span class="is-clickable">
                        <i class="fas fa-ellipsis-h"></i>
                      </span>

                      <template #content>
                        <div class="box">
                          <aside class="menu">
                            <ul class="menu-list">
                              <template v-if="report.reportBuilderVersion >= 2">
                                <li>
                                  <a @click.prevent="onRouteToEditReport(report.id)" class="help"
                                    >Edit</a
                                  >
                                </li>
                                <div style="border: 1px solid whitesmoke"></div>
                                <li>
                                  <a @click.prevent="onDuplicateReport(report.id)" class="help"
                                    >Duplicate</a
                                  >
                                </li>
                                <div style="border: 1px solid whitesmoke"></div>
                                <li>
                                  <a @click.prevent="onDeleteReport(report.id)" class="help"
                                    >Delete</a
                                  >
                                </li>
                              </template>
                              <template v-else>
                                <li>
                                  <a @click.prevent="onDeleteReport(report.id)" class="help"
                                    >Delete</a
                                  >
                                </li>
                                <li
                                  v-if="['Published', 'Published!'].includes(report.reportStatus)"
                                >
                                  <a class="help"
                                    >Contact Support to retrieve you're published links</a
                                  >
                                </li>
                              </template>
                            </ul>
                          </aside>
                        </div>
                      </template>
                    </Popper>
                  </div>
                  <div
                    class="is-flex is-justify-content-space-between"
                    style="position: absolute; top: 95%; z-index: 985; left: 5px; right: 5px"
                  >
                    <span
                      class="tag"
                      :class="
                        report.reportStatus == 'Published' || report.reportStatus == 'Published!'
                          ? 'is-success'
                          : 'is-danger'
                      "
                      >{{ report.reportStatus }}</span
                    >
                    <span>
                      <Popper placement="top">
                        <span class="tag"
                          ><a class="is-underlined is-clickable"
                            ><strong>Analytics</strong></a
                          ></span
                        >
                        <template #content>
                          <div class="box">
                            <div class="level">
                              <span class="level-left">
                                <i class="fas fa fa-eye"></i>
                              </span>
                              <span class="level-right">
                                <span class="level-item">
                                  {{ report.reportViews }}
                                </span>
                                <span>views</span>
                              </span>
                            </div>
                          </div>
                        </template>
                      </Popper>
                    </span>
                  </div>

                  <div
                    v-if="report.reportBuilderVersion >= 2"
                    :style="`transform: scale(${450 / 1400},${
                      290 / 800
                    }); width: 1400px; height:250px;transform-origin: left top`"
                    @click.prevent="onRouteToEditReport(report.id)"
                    class="is-clickable mb-5"
                  >
                    <ReportRender
                      :headingFont="report.headingFont"
                      :bodyFont="report.bodyFont"
                      :sectionBgColor="report.sections[0]?.sectionBgColor"
                      :generatedJson="report.sections[0]?.generatedJson"
                      :sectionBgImage="report.sections[0]?.sectionBgImage"
                      :sectionBgImageSize="report.sections[0]?.sectionBgImageConfig?.size"
                      :sectionHeight="report.sections[0]?.sectionHeight"
                      :sectionFlex="report.sections[0]?.sectionFlex"
                      :childSections="report.sections[0]?.sections"
                      :sectionOrder="report.sections[0]?.order"
                      :isCoverSection="report.sections[0]?.isCoverSection"
                      :isLastSection="report.sections[0]?.order == report.sections.length"
                      :hideChapterModules="true"
                    ></ReportRender>
                  </div>
                  <div
                    v-else
                    :style="` width: 450px; height:290px;transform-origin: left top; background-image: url('${report.reportCover}');background-size:contain;   background-position:center;
        background-repeat:no-repeat;`"
                  >
                    {{ report.reportTitle }}
                  </div>
                </div>
              </div>
              <template v-if="reports?.list?.length && reports?.list?.length % 3">
                <div
                  class="column is-4-desktop is-4-tablet is-12-mobile"
                  :key="i"
                  v-for="i in 3 - (reports?.list?.length % 3)"
                >
                  <div
                    :style="`transform: scale(${400 / 1400},${
                      250 / 800
                    }); width: 1400px; height:250px;transform-origin: left top`"
                    class="is-clickable"
                  >
                    <!--Empty column to offset  -->
                  </div>
                </div>
              </template>

              <div class="column is-12">
                <div class="block"></div>
                <Pagination :collection="reports" />
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, inject } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import CollectionManager from '@/services/collectionManager'

import Report from '@/services/reports'
import CoverSection, { generateInitialTitlePageContent } from '@/services/coverSections/'

import yearlyStore from '@/composables/yearlyStore'
import commonModals from '@/composables/commonModals'
import reportFunctions from '@/composables/reportFunctions'

import ReportRender from '@/components/ReportRender'
import LogoRandomizer from '@/components/LogoRandomizer'
import Pagination from '@/components/Pagination'

export default {
  name: 'DashboardPage',
  components: { ReportRender, LogoRandomizer, Pagination },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { routeToEditReport, deleteReport, duplicateReport } = reportFunctions()
    const { user, hasTrial, hasLegacy } = yearlyStore()
    const reports = ref(
      CollectionManager.create({
        ModelClass: Report,
        filters: { firebaseId: user.value.firebaseId }
      })
    )
    const isLoading = ref(false)
    const Alert = inject('$alert')
    const { showBaseModal, showUpgradeFromFreeModal, showWelcomeToFreeModal } = commonModals()

    function generateInitialPage() {
      let generatedJson = generateInitialTitlePageContent()

      generatedJson = {
        ...generatedJson,
        content: generatedJson.content.map((content) => {
          return {
            ...content,
            marks: [{ type: 'textStyle', attrs: { color: 'rgba(255,255,255,1)' } }]
          }
        })
      }
      const section = {
        sectionHeight: 800,
        generatedJson: generatedJson,
        order: 1,
        sectionBgImage: `https://yearly-2.vercel.app/img/cover3.jpeg`,
        isCoverSection: true
      }

      return section
    }
    async function onNewReport() {
      if (reports.value && reports.value.list.length > 0 && hasTrial.value && !hasLegacy.value) {
        await showUpgradeFromFreeModal()

        return
      }
      const report = Report.create({ reportTitle: 'New Report', sections: [generateInitialPage()] })

      try {
        const res = await Report.api.create(user.value.firebaseId, report)

        onRouteToEditReport(res.id)
      } catch (e) {
        console.log(e)
      }
    }
    function onRouteToEditReport(reportId) {
      routeToEditReport(reportId)
    }
    async function onDeleteReport(rid) {
      let conf = confirm('Are you sure you want to delete this report ')
      if (conf) {
        try {
          isLoading.value = true

          await deleteReport(rid)
          await reports.value.refresh()
        } catch (e) {
          console.log(e)
        } finally {
          isLoading.value = false
        }
      }
    }
    async function onDuplicateReport(rid) {
      if (reports.value && reports.value.list.length > 0 && hasTrial.value && !hasLegacy.value) {
        await showUpgradeFromFreeModal()

        return
      }
      try {
        isLoading.value = true

        const res = await duplicateReport(rid)
        onRouteToEditReport(res.report.id)
      } catch (e) {
        console.log(e)
      } finally {
        isLoading.value = false
      }
    }

    onMounted(async () => {
      try {
        isLoading.value = true

        await reports.value.refresh()
      } catch (e) {
        console.log(e)
        Alert.alert({ message: 'An error occured listing reports', type: 'error' })
      } finally {
        isLoading.value = false
      }
    })
    return {
      reports,
      onRouteToEditReport,
      isLoading,
      onNewReport,
      onDeleteReport,
      onDuplicateReport
    }
  }
}
</script>

<style lang="scss" scoped>
.report-preview {
  @include mobile {
  }
  @include tablet {
  }
}
</style>
